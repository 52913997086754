import "./report.css"
import {
    LineChart,
    Line,
    XAxis,
    CartesianGrid,
    Tooltip,
    
    ResponsiveContainer,
  } from "recharts";
  

export default function Report({title, data, dataKey, grid}) {

   
  return (
    <div className="report">

      <h3 className="reportTitle">{title}</h3>
        <ResponsiveContainer width="100%" aspect={4/1}>
            <LineChart data={data}>
            <XAxis dataKey={dataKey} stroke="#5550bd" />
            <Line type="monotone" dataKey="Active User" stroke="#5550bd" />
            <Tooltip />
           {grid && <CartesianGrid stroke="#e0dfdf" strokeDasharray="5 5"/>}
            </LineChart>
    
        </ResponsiveContainer>
    </div>
  )
}

